var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('CourseEditAttendanceModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"attendanceParticipant":_vm.modalData.existingItem},on:{"close":function($event){_vm.modalData.showModal = false},"updateCourseParticipant":_vm.registerSingleAttendance}})],1):_vm._e(),(_vm.course.courseLocation.areaId)?_c('CourseHeader',{staticClass:"mb-6",attrs:{"course":_vm.course}}):_vm._e(),_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"item-key":"userId","data-cy":"attendanceParticipantTable","headers":_vm.headers,"items":_vm.attendances,"items-per-page":50,"scrollable":false,"search":_vm.search,"show-select":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"title font-weight-light align-self"},[_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Tilbake","icon":"mdi-arrow-left"},on:{"handleClick":_vm.navigateToAttendanceList}}),_vm._v("Fremmøte ")],1)]),_c('v-col',{staticClass:"pb-0 d-flex justify-end",attrs:{"cols":"3"}},[(_vm.showRegisterButton)?_c('v-btn',{attrs:{"color":"primary","data-cy":"batchRegisterAttendance"},on:{"click":_vm.batchRegisterAttendance}},[_vm._v(" Registrer fremmøte ")]):_vm._e()],1),_c('v-col',{staticClass:"py-0 d-flex align-end",attrs:{"cols":"6"}},[_c('div',{staticClass:"subtitle-1 font-weight-light align-self"},[_vm._v(" Dato: "),_c('strong',[_vm._v(" "+_vm._s(_vm.getFormattedDateTime)+" ")])])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"pa-0 ma-0 mt-2",attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.noOfHours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isRegistered ? item.noOfHours || "Ikke møtt" : item.noOfHours || "Avventer registrering")+" ")]}},{key:"item.isRegistered",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Registrert" : "Ikke registrert")+" ")]}},{key:"item.updatedByUserFullName",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value || "-")+" ")]}},{key:"item.updated",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDate(value) : "-")+" ")]}},(!_vm.isCourseDone)?{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"editAttendanceParticipant"},on:{"click":function($event){return _vm.openAttendanceParticipantModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger fremmøte")])]),(item.isRegistered)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"deleteAttendanceParticipant"},on:{"click":function($event){return _vm.deleteCourseParticipantAttendance(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Slett fremmøte")])]):_vm._e()]}}:null],null,true),model:{value:(_vm.selectedAttendances),callback:function ($$v) {_vm.selectedAttendances=$$v},expression:"selectedAttendances"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }