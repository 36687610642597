











































































































import { api } from "@/api/api";
import {
  ApiCourseParticipantAttendanceBaseDto,
  ApiCourseParticipantAttendanceBaseDtoValidationResponseDto,
  ApiGetCourseParticipantAttendanceDto,
} from "@/api/generated/Api";
import CourseEditAttendanceModal from "@/components/course/details/CourseEditAttendanceModal.vue";
import CourseHeader from "@/components/course/details/CourseHeader.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatDateTime, formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import axios from "axios";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";

export default defineComponent({
  name: "CourseAttendanceParticipantPage",
  components: {
    CourseHeader,
    BaseModal,
    CourseEditAttendanceModal,
    BaseTableFiltered,
    BaseTooltipIconButton,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const search = ref("");
    const modalData = ref(getInitialModalData());
    const course = computed(() => store.state.courses.course);
    const attendances = ref<ApiGetCourseParticipantAttendanceDto[]>();
    const selectedAttendances = ref<ApiGetCourseParticipantAttendanceDto[]>();

    const loadAttendances = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const courseParticipants = (
          await api.course.getCourseParticipantAttendancesByCourseIdAsync(parseInt(route.params.id), {
            ScheduleStart: route.params.startDate,
          })
        ).data;

        attendances.value = courseParticipants.map((participant) => ({
          ...participant,
          isSelectable: !participant.isRegistered,
        }));
      });
    };

    const navigateToAttendanceList = () => {
      router.push({
        name: SingleCourseRouteNames.CourseAttendance,
        params: {
          id: route.params.id,
        },
      });
    };

    const openAttendanceParticipantModal = useOpenModal(ModalType.Edit, "fremmøte", modalData);

    const batchRegisterAttendance = async () => {
      if (!selectedAttendances.value) {
        return;
      }
      selectedAttendances.value.map((participant) => (participant.noOfHours = participant.scheduleHours));
      await updateCourseParticipantAttendance(selectedAttendances.value);
      selectedAttendances.value = [];
    };

    const registerSingleAttendance = async (attendanceEntry: ApiCourseParticipantAttendanceBaseDto) => {
      await updateCourseParticipantAttendance([attendanceEntry]);
    };

    const deleteCourseParticipantAttendance = (attendanceEntry: ApiCourseParticipantAttendanceBaseDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.deleteCourseParticipantAttendanceAsync(+route.params.id, attendanceEntry.userId, {
          ScheduleStart: attendanceEntry.scheduleStart,
        });
        await loadAttendances();
      });
    };

    const updateCourseParticipantAttendance = async (attendances: ApiCourseParticipantAttendanceBaseDto[]) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.course.batchMergeCourseParticipantAttendancesByCourseIdAsync(+route.params.id, { attendances });
          openNotification(store, NotificationItemType.Success, "Fremmøte er registrert");
          await loadAttendances();
        } catch (error: unknown) {
          if (!axios.isAxiosError(error) || !error.response || !isValidationErrorResponse(error.response?.data)) {
            return;
          }
          const { validationList } = error.response?.data;
          validationList?.forEach((item) => {
            item.validationErrors?.forEach((message) => {
              openNotification(store, NotificationItemType.Warning, `Valideringsfeil: ${message}`);
            });
          });
        }
      });
    };

    onMounted(() => {
      loadAttendances();
    });

    return {
      search,
      modalData,
      headers,
      attendances,
      selectedAttendances,
      openAttendanceParticipantModal,
      deleteCourseParticipantAttendance,
      batchRegisterAttendance,
      registerSingleAttendance,
      navigateToAttendanceList,
      course,
      showRegisterButton: computed(() => !!selectedAttendances.value?.length),
      getFormattedDateTime: computed(() =>
        attendances.value && attendances.value?.length >= 1 ? formatDateTime(attendances.value[0].scheduleStart) : "-"
      ),
      formatDate,
      isCourseDone: course.value.status === CourseStatus.Closed,
    };
  },
});

const headers = [
  {
    text: "Navn",
    value: "userFullName",
  },
  {
    text: "Fremmøte registrert",
    value: "noOfHours",
  },
  {
    text: "Antall timer i timeplan",
    value: "scheduleHours",
  },
  {
    text: "Status",
    value: "isRegistered",
  },
  {
    text: "Oppdatert av",
    value: "updatedByUserFullName",
  },
  {
    text: "Sist oppdatert",
    value: "updated",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
];

const isValidationErrorResponse = (
  response: unknown
): response is ApiCourseParticipantAttendanceBaseDtoValidationResponseDto =>
  typeof response === "object" &&
  response !== null &&
  ("validationList" in response || "generalValidationErrors" in response);
